import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Link from '../utils/link'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReadyToGetStarted from '../components/ready-to-get-started'
import CircleCard from '../components/cards/circle-card'

class ArticlesGuides extends Component {

  render() {
    let posts = this.props.data.allWpPost

    return (
      <>
        <Seo title={`Articles & Guides - Home Design Co.`} bodyClass='shop-archive' description={`Articles and guides to help you get started with our made-to-measure kitchens, bathrooms, laundries and more.`} />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/shop/'>Articles &amp; Guides</Link></li>
            </ul>
          </div>
        </section>
        <section className='circle-image-items vertical'>
          <div className='circle-image-items__inner'>
            <h1 className='circle-image-items__heading no-content'>Articles &amp; Guides</h1>
            { posts?.nodes?.length &&
              <div className='circle-image-items__items'>
                { posts.nodes.map((el, i) => {
                  let props = {
                    image: el.featuredImage?.node,
                    title: el.title,
                    content: el.acf.subtitle,
                    text: 'Read More',
                    link: `/guide/${el.slug}/`,
                  }
                  return (
                    <CircleCard {...el} order={i} layout={'vertical'} key={i} {...props} />
                  )
                })}
              </div>
            }
          </div>
        </section>
        <ReadyToGetStarted />
      </>
    )
  }
}

export const guidesQuery = graphql`
  query {
    allWpPost(
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        slug
        date
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 500
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
        acf {
          subtitle
        }
      }
    }
  }
`

export default ArticlesGuides
